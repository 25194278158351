@import url('https://fonts.googleapis.com/css?family=Google+Sans');

/*
  This hack avoids Chrome terrible yellow color on autofilled inputs

  Source: http://webagility.com/posts/the-ultimate-list-of-hacks-for-chromes-forced-yellow-background-on-autocompleted-inputs
*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-transition-delay: 99999s;
}

::selection {
  color: #fff;
  background: #3D97BA; /* WebKit/Blink Browsers */
}

::-moz-selection {
  color: #fff;
  background: #3D97BA; /* Gecko Browsers */
}

.update-notification {
  background-color: #222;
  color: #fff;
  display:block;
  position: absolute;
  bottom:0;
  right:0;
  padding:0.8em 1.2em;
  text-decoration: none;
}

.recharts-tooltip-wrapper {
  z-index: 99999;
}
